<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import Layout from "../../layouts/main.vue";
import Validate from "@/service/ValidService";
import { useRoute, useRouter } from "vue-router";
import { FormInstance } from "element-plus";
import MethodService from "../../service/MethodService";
import Data from "./dataFormGuide";
import mushroom from "cem-primary-api";
import toastr from "toastr";
import LanguaService from "@/service/LanguageService";
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validateFlag = ref(false)
const route = useRoute();
const router = useRouter();
const dataForm = reactive({ value: MethodService.copyObject(Data.dataForm) });
const rulesForm = reactive({
  title: [Validate.required, Validate.checkSpaceString],
  file_id: [],
});
const nameFile = ref("");
const guideTitle = ref("");

const uploadHuongDan = async () => {
  try {
    let typeFile = document.getElementById("uploadFile").files[0].type;
    if (typeFile) {
      if (typeFile !== "application/pdf") {
        toastr.warning(LanguaService[lang.value].t_select_format_pdf);
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadFile").files[0],
        });
        if (res.result) {
          nameFile.value = document.getElementById("uploadFile").files[0]?.name;
          dataForm.value.file_id = res.result;
        } else nameFile.value = "";
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const removeFile = () => {
  dataForm.value.file_id = null;
  nameFile.value = null;
  document.getElementById("uploadFile").value = "";
};
const addNewGuide = (formEl) => {
  validateFlag.value = true
  formEl.validate(async (valid) => {
    if (valid) {
      console.log(valid);
      try {
        const user_manual_object = {
          ...dataForm.value,
          title: dataForm.value.title.replace(/\s+/g, " ").trim(),
        };
        const response = await mushroom.user_manual.createAsync(
          user_manual_object
        );
        console.log(response);
        toastr.success(LanguaService[lang.value].toastr_add_success);
        router.push({ name: "HuongDanSuDung" });
      } catch (e) {
        console.error("Có lỗi: %o", e);
        toastr.error(LanguaService[lang.value].t_not_upload_guide_required);
      }
    }
  });
};

const getGuideDetails = async () => {
  const id = route.params.id;
  if (id) {
    try {
      const response = await mushroom.user_manual.findByIdAsync({
        id: id,
      });
      if (response.result) {
        dataForm.value = response.result;
        guideTitle.value = response.result.title;
        // console.log(response.result)
        nameFile.value = "file_" + response.result.file_id + ".pdf";
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
};

const updateGuide = (formEl) => {
  validateFlag.value = true
  formEl.validate(async (valid) => {
    if (valid) {
      const user_manual_object = {
        ...dataForm.value,
        title: dataForm.value.title.replace(/\s+/g, " ").trim(),
      };
      console.log(user_manual_object);
      try {
        const response = await mushroom.user_manual.partialUpdateAsync(
          user_manual_object
        );
        console.log(response);
        if (response.result == user_manual_object.id) {
          toastr.success(LanguaService[lang.value].toastr_update_success);
          router.push({ name: "HuongDanSuDung" });
        } else console.log("Dữ liệu không thay đổi");
      } catch (error) {
        console.error("Có lỗi: %o", error);
        toastr.error(LanguaService[lang.value].t_not_upload_guide_required);
      }
    }
  });
};
watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);
onMounted(() => {
  getGuideDetails();
});
</script>
<template>
  <Layout>
    <div class="card" style="min-height: 100vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0 guideTitle">
          {{ route.params.id ? guideTitle : $t("t_add_guide") }}
        </h3>
      </div>
      <div class="card-body" style="padding: 1rem">
        <div class="main-body">
          <el-form
            class="form-guide"
            :rules="rulesForm"
            ref="ruleFormRef"
            :model="dataForm.value"
          >
            <el-form-item
              :label="`${$t('t-guide')} (${$t('t_type')}: .pdf)`"
              prop="file_id"
            >
              <div
                class="dropzone position-relative mb-2"
                style="height: 180px; row-gap: 0; min-height: 40px"
              >
                <div role="button">
                  <div class="mb-1">
                    <label
                      for="uploadFile"
                      style="background-color: #fff"
                      role="button"
                    >
                      <i
                        class="display-4 text-muted ri-upload-cloud-2-fill"
                      ></i>
                    </label>
                  </div>
                </div>
                <h4 class="text-center">{{ $t("t-upload") }}</h4>
                <input
                  type="file"
                  id="uploadFile"
                  style="display: none"
                  accept=".pdf"
                  v-on:change="uploadHuongDan"
                />
              </div>
              <div
                class="d-flex justify-content-between align-items-center file-container"
                v-if="nameFile"
              >
                <div class="d-flex">
                  <i class="ri-file-pdf-line d-flex align-items-center"></i>
                  <span class="ms-2">{{ nameFile }}</span>
                </div>
                <div>
                  <i
                    class="ri-close-circle-line delete-icon"
                    @click="removeFile"
                  ></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              class="mb-4"
              :label="$t('t-guide-title')"
              prop="title"
            >
              <el-input
                v-model="dataForm.value.title"
                :placeholder="$t('t-placeholder-guide-title')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="text-center">
            <button
              class="btn btn-soft-secondary btn-border mx-3"
              @click="router.go(-1)"
            >
              {{ $t("t-back") }}
            </button>
            <el-button
              type="button"
              style="height: 38px"
              class="btn btn-secondary btn-border"
              v-if="router.currentRoute.value.name == 'ThemHuongDanSuDung'"
              @click="addNewGuide(ruleFormRef)"
            >
              {{ $t("t-add") }}
            </el-button>
            <el-button
              type="button"
              style="height: 38px"
              class="btn btn-secondary btn-border"
              v-else
              @click="updateGuide(ruleFormRef)"
            >
              {{ $t("t-update") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" scoped>
.guideTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.el-form-item {
  display: block;
}
.dropzone {
  &:hover {
    border: 2px dashed #409eff;
  }
}
.file-container {
  padding: 0 10px;
  &:hover {
    background-color: #f5f7fa;
    cursor: pointer;
    span {
      color: #409eff;
      font-weight: 500;
    }
    .delete-icon {
      display: block;
    }
  }
  .delete-icon {
    display: none;
    font-size: medium;
    &:hover {
      color: #409eff;
      font-weight: 500;
    }
  }
}
::v-deep .el-form-item__label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
::v-deep .el-input__inner {
  padding: 5px 5px !important;
  font-size: 1.1em;
  height: auto !important;
}
::v-deep .el-textarea__inner {
  font-size: 1.1em;
  padding: 6px 16px;
}
</style>
